<template>
    <page-title
        icon="bi-gear-fill"
        title="掛率マスタ"
    >
    </page-title>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
                <table-normal class="table">
                    <thead>
                        <tr class="table-dark">
                            <th class="col-md-2 text-center">掛率記号</th>
                            <th class="col-md-3 text-center">代理店掛率</th>
                            <th class="col-md-3 text-center">指定代理店掛率</th>
                            <th class="col-md-3 text-center">グループ会社掛率</th>
                            <th class="col-md-3 text-center">工場出し掛率</th>
                            <th class="col-md-3 text-center">工場掛率</th>
                            <th class="col-md-2" v-if="$store.getters['auth/canManageWholesaleRate']()"></th>
                            <th class="col-md-2" v-if="$store.getters['auth/canManageWholesaleRate']()"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="wholesale_rates.length">
                            <tr v-for="(wholesale_rate, index) in wholesale_rates" :key="wholesale_rate.wholesale_rate_id">
                                <td class="align-middle">
                                    <form-input
                                        v-if="$store.getters['auth/canManageWholesaleRate']()"
                                        v-model="wholesale_rate.rate_mark"
                                        :required="true"
                                        maxlength="5"
                                        :form="`wholesale_rate_${wholesale_rate.wholesale_rate_id}`"
                                    ></form-input>
                                    <read-only
                                        v-else
                                        :text="wholesale_rate.rate_mark"
                                        class="text-center"
                                    ></read-only>
                                </td>

                                <td class="align-middle">
                                    <div class="input-group" v-if="$store.getters['auth/canManageWholesaleRate']()">
                                        <form-input-number
                                            v-model="wholesale_rate.distributor_rate"
                                            :required="true"
                                            min="0"
                                            :form="`wholesale_rate_${wholesale_rate.wholesale_rate_id}`"
                                        ></form-input-number>
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div class="input-group" v-else>
                                        <read-only
                                            class="text-end"
                                            :text="wholesale_rate.distributor_rate"
                                        ></read-only>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </td>

                                <td class="align-middle">
                                    <div class="input-group" v-if="$store.getters['auth/canManageWholesaleRate']()">
                                        <form-input-number
                                            v-model="wholesale_rate.designated_distributor_rate"
                                            :required="true"
                                            min="0"
                                            :form="`wholesale_rate_${wholesale_rate.wholesale_rate_id}`"
                                        ></form-input-number>
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div class="input-group" v-else>
                                        <read-only
                                            class="text-end"
                                            :text="wholesale_rate.designated_distributor_rate"
                                        ></read-only>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </td>

                                <td class="align-middle">
                                    <div class="input-group" v-if="$store.getters['auth/canManageWholesaleRate']()">
                                        <form-input-number
                                            v-model="wholesale_rate.group_company_rate"
                                            :required="true"
                                            min="0"
                                            :form="`wholesale_rate_${wholesale_rate.wholesale_rate_id}`"
                                        ></form-input-number>
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div class="input-group" v-else>
                                        <read-only
                                            class="text-end"
                                            :text="wholesale_rate.group_company_rate"
                                        ></read-only>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </td>

                                <td class="align-middle">
                                    <div class="input-group" v-if="$store.getters['auth/canManageWholesaleRate']()">
                                        <form-input-number
                                            v-model="wholesale_rate.shipping_rate"
                                            :required="true"
                                            min="0"
                                            :form="`wholesale_rate_${wholesale_rate.wholesale_rate_id}`"
                                        ></form-input-number>
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div class="input-group" v-else>
                                        <read-only
                                            class="text-end"
                                            :text="wholesale_rate.shipping_rate"
                                        ></read-only>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </td>

                                <td class="align-middle">
                                    <div class="input-group" v-if="$store.getters['auth/canManageWholesaleRate']()">
                                        <form-input-number
                                            v-model="wholesale_rate.factory_rate"
                                            :required="true"
                                            min="0"
                                            :form="`wholesale_rate_${wholesale_rate.wholesale_rate_id}`"
                                        ></form-input-number>
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div class="input-group" v-else>
                                        <read-only
                                            class="text-end"
                                            :text="wholesale_rate.factory_rate"
                                        ></read-only>
                                        <span class="input-group-text">%</span>
                                    </div>
                                </td>

                                <td class="align-middle text-end" v-if="$store.getters['auth/canManageWholesaleRate']()">
                                    <form @submit.prevent="update(wholesale_rate)" :id="`wholesale_rate_${wholesale_rate.wholesale_rate_id}`">
                                        <button-exec-update
                                            text="保存"
                                        ></button-exec-update>
                                    </form>
                                </td>
                                <td class="align-middle text-start" v-if="$store.getters['auth/canManageWholesaleRate']()">
                                    <button-exec-delete
                                        type="button"
                                        @click="$refs.confirm_remove.show(); setTargetWholesaleRate(wholesale_rate, index)"
                                    ></button-exec-delete>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="6"><alert-no-record></alert-no-record></td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot v-if="$store.getters['auth/canManageWholesaleRate']()">
                        <tr>
                            <td class="align-middle">
                                <form-input
                                    v-model="additional_wholesale_rate.rate_mark"
                                    :required="true"
                                    maxlength="5"
                                    form="additional_wholesale_rate"
                                ></form-input>
                            </td>

                            <td class="align-middle">
                                <div class="input-group">
                                    <form-input-number
                                        v-model="additional_wholesale_rate.distributor_rate"
                                        :required="true"
                                        min="0"
                                        form="additional_wholesale_rate"
                                    ></form-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </td>

                            <td class="align-middle">
                                <div class="input-group">
                                    <form-input-number
                                        v-model="additional_wholesale_rate.designated_distributor_rate"
                                        :required="true"
                                        min="0"
                                        form="additional_wholesale_rate"
                                    ></form-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </td>

                            <td class="align-middle">
                                <div class="input-group">
                                    <form-input-number
                                        v-model="additional_wholesale_rate.group_company_rate"
                                        :required="true"
                                        min="0"
                                        form="additional_wholesale_rate"
                                    ></form-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </td>

                            <td class="align-middle">
                                <div class="input-group">
                                    <form-input-number
                                        v-model="additional_wholesale_rate.shipping_rate"
                                        :required="true"
                                        min="0"
                                        form="additional_wholesale_rate"
                                    ></form-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </td>

                            <td class="align-middle">
                                <div class="input-group">
                                    <form-input-number
                                        v-model="additional_wholesale_rate.factory_rate"
                                        :required="true"
                                        min="0"
                                        form="additional_wholesale_rate"
                                    ></form-input-number>
                                    <span class="input-group-text">%</span>
                                </div>
                            </td>
                            <td class="align-middle text-end">
                                <form @submit.prevent="create()" id="additional_wholesale_rate">
                                    <button-exec-create
                                        text="追加"
                                    ></button-exec-create>
                                </form>
                            </td>
                        </tr>
                    </tfoot>
                </table-normal>
        </template>
    </section>

    <confirm-dialog ref="confirm_remove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import WholesaleRate from '@/models/entities/wholesale-rate';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormInputNumber from '@/components/forms/FormInputNumber';
import ReadOnly from '@/components/forms/ReadOnly';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import TableNormal from '@/components/tables/TableNormal.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'WholesaleRate',
    components: {
        ConfirmDialog,
        InlineLoader,
        FormInput,
        FormInputNumber,
        ReadOnly,
        ButtonExecCreate,
        ButtonExecUpdate,
        ButtonExecDelete,
        TableNormal,
        AlertNoRecord,
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: true,

            //表示データ
            wholesale_rates: [],

            //新規登録掛率
            additional_wholesale_rate: new WholesaleRate(),
            //削除対象掛率
            delete_target: {
                wholesale_rate: new WholesaleRate(),
                index: null,
            }
        }
    },
    mounted() {
        this.$http.get('/wholesale-rate')
        .then(response => {
            for (let row of response.data) {
                this.wholesale_rates.push(new WholesaleRate(row));
            }
        })
        .finally(() => {
            this.loading = false;
        });
    },
    computed: {
    },
    methods: {
        //削除対象の規格項目をセットする
        setTargetWholesaleRate(wholesale_rate = new WholesaleRate(), index = null) {
            this.delete_target.wholesale_rate = wholesale_rate;
            this.delete_target.index = index;
        },
        //新規登録
        create() {
            this.startScreenLoading();

            this.$http.post('/wholesale-rate', this.additional_wholesale_rate)
            .then(response => {
                this.wholesale_rates.push(new WholesaleRate(response.data));
                this.additional_wholesale_rate = new WholesaleRate();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //編集
        update(wholesale_rate) {
            this.startScreenLoading();

            this.$http.put(`/wholesale-rate/${wholesale_rate.wholesale_rate_id}`, wholesale_rate)
            .then(() => {
                this.showMessage('編集しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/wholesale-rate/${this.delete_target.wholesale_rate.wholesale_rate_id}`)
            .then(() => {
                this.wholesale_rates.splice(this.delete_target.index, 1);
                this.showMessage('削除しました');

                //値をリセット
                this.setTargetWholesaleRate();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>

</style>
